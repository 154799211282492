function setupVideoLoader(button) {
    button.addEventListener('click', function(event){
        event.preventDefault();
        const iframeString = this.dataset.video;
        if(iframeString) {
            this.parentElement.innerHTML = iframeString;
        }
    });
}

function init() {
    const videoLoaders = [].slice.call(document.querySelectorAll('.video-grid__load-video'));
    videoLoaders.forEach(setupVideoLoader);
}

export default { init};