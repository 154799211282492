// -- JM Modules --------------------
import classOnScroll from 'jm-libjs/classonscroll';
import classOnClick from 'jm-libjs/classonclick';
import lazyLoadImages from 'jm-libjs/lazy';
import inputValueUpdate from 'jm-libjs/inputvalueupdate';
import scroller from 'jm-libjs/scroller';
import { initErrors, handleError } from 'jm-libjs/errors';

// -- Theme modules --------------------
import cookieNoticeHandler from './modules/cookieNoticeHandler';
import loaderHandler from './modules/loaderHandler';
import videoLoader from './modules/videoLoader';
import errorHandler from './handleError';

(() => {
	const homeURL = `${window.location.protocol}//${window.location.hostname}`;
	initErrors(errorHandler(homeURL));
	window.handleError = handleError;

	// -- Setup JM Modules --------------------
	try {
		classOnClick.init();
		classOnScroll.init();
	} catch (error) {
		handleError(error);
	}
	try {
		lazyLoadImages.init();
		inputValueUpdate.init();
	} catch (error) {
		handleError(error);
	}
	try {
		scroller.init();
	} catch (error) {
		handleError(error);
	}

	// -- Setup Theme Modules --------------------
	try {
		videoLoader.init();
	} catch (error) {
		handleError(error);
	}
	try {
		loaderHandler.init();
	} catch (error) {
		handleError(error);
	}
	try {
		cookieNoticeHandler.init();
	} catch (error) {
		handleError(error);
	}
})();
