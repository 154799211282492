import cookieNotice from 'jm-libjs/cookie-notice';

// init :: _ -> (_ -> Boolean)
function init() {
    const cookieText = document.querySelector('[data-js="cookie_accept_text"]').innerHTML;
    const privacyUrl = document.querySelector('[data-js="privacy_page_url"]').innerHTML;
    cookieNotice(cookieText, privacyUrl, {
        acceptButtonClass: 'btn btn--cta',
        ppButtonClass: 'btn btn--cta alt',
    });
    return true;
}

export default { init };
