import cookies from 'jm-libjs/cookies';
import { handleError } from 'jm-libjs/errors';

const blur = new Event('blur');
const focus = new Event('focus');

function deleteLoader(loader) {
    loader.parentNode.removeChild(loader);
    document.body.classList.remove('will-load');
    document.body.classList.add('loaded');
    // Make sure the slider starts
    window.dispatchEvent(focus);
}

function onFinish(loader) {
    loader.classList.add('finish');
    cookies.set('hasloaded', 'true', 30);
    setTimeout(() => loader.classList.add('finished'), 1000);
    setTimeout(() => deleteLoader(loader), 1300);
}

// Run once load is done
function runAfterAnimation(loader, trys = 0) {
    try {
        // Recurse if not loaded, up to a maximum of 5 times
        if (trys <= 5 && document.readyState !== 'complete') return runAfterAnimation(loader, trys + 1);
        // 1000ms Fade out
        onFinish(loader);
    } catch (error) {
        handleError(error);
        return deleteLoader(loader);
    }
}

function init() {
    const loader = document.querySelector('.loader');
    if (!loader) return;
    try {
        // Delete the loader if has already loaded
        if (cookies.get('hasloaded') === 'true') return deleteLoader(loader);
        loader.classList.add('load');
        document.body.classList.add('will-load');
        // This is here to stop the slider running whilst loading
        window.dispatchEvent(blur);
        // 3000ms load animation plus 1500ms wait
        setTimeout(() => runAfterAnimation(loader), 4500);
    } catch (error) {
        handleError(error);
        return deleteLoader();
    }
}

export default { init };
