import axios from 'axios';
import getBrowser from 'jm-libjs/get-browser';

const errorHandler = errorLogURL => error => {
    if (process.env.NODE_ENV === 'development') console.error(error);
    const browser = getBrowser();
    const errorString = `${error.message}\nBROWSER: ${browser.name} ${
        browser.version
    }\nSTACK TRACE:\n${error.stack.split(' at ').join(' at ')}`;
    axios.post(errorLogURL, { error: errorString }, { timeout: 10000 }).catch(console.error);
};

export default errorHandler;
